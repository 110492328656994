import React from 'react';
import Signature from '../components/Signature';

export default function EmailSignature({ location: { state } }) {

    const downloadEmail = () => {
        var email = document.documentElement.getElementsByClassName('emailSign')[0].innerHTML;
        let data = new Blob([email], { type: 'data:text/html' });
        let url = window.URL.createObjectURL(data);

        let tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'emailSign.html');
        tempLink.click();

    }
    return (
        <div className="bg-gradient-to-b from-black to-slate min-h-screen text-white py-24">
            <div className="max-w-2xl mx-auto flex flex-col items-center justify-center ">
                <div className="my-4 text-center">
                    <h1 className="uppercase tracking-widest text-2xl my-2">Fluid Email</h1>
                    <p className="max-w-sm">Your signature has been generated succesfully.</p>
                </div>

                <Signature {...state} />
                <button onClick={downloadEmail} className="bg-slate text-white uppercase text-xl rounded py-2 px-8 tracking-widest hover:bg-orange transition-all my-4">Download</button>

                <div className="my-4">
                    <p className="mb-2">Please follow these instructions to install.</p>
                    <ol className="list-decimal ml-4">
                        <li className="py-1">Download your signature</li>
                        <li className="py-1">Open the signature file in a web browser</li>
                        <li className="py-1">Select all [CMD + A]</li>
                        <li className="py-1">Copy the signature [CMD + C]</li>
                        <li className="py-1">Paste your signature into your Gmail settings [CMD + V]</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}