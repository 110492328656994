import React from "react"

export default function Signature({
  name,
  email,
  title,
  mcvAward,
  agencyAward,
  mobile,
  officePhone,
  discord,
  termsSwitch,
}) {
  const today = new Date()
  const year = today.getFullYear()

  const formatMobileNumber = (mobile) => {
    const value = mobile.replace(/\s/g, "").split("")

    if (value[0] === "+" && value[3] === "(" && value[5] === ")") {
      value.splice(3, 0, " ")
      value.splice(11, 0, " ")
    }

    if (value[0] === "+" && value[3] === "0") {
      value.splice(3, 0, "(")
      value.splice(5, 0, ")")

      value.splice(3, 0, " ")
      value.splice(11, 0, " ")
    }

    if (value[0] === "0") {
      value.splice(0, 0, "+44")
      value.splice(1, 0, " (")
      value.splice(3, 0, ")")

      value.splice(8, 0, " ")
    }

    return value.join("")
  }

  const maxWidth = mcvAward && agencyAward ? "500px" : "400px"

  return (
    <div className="emailSign">
      <div>
        <table
          style={{ maxWidth: "500px", border: "0", display: "block" }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td
                style={{
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#999999",
                  padding: 0,
                }}
                valign="top"
              >
                <br />
                <a
                  href={`mailto:${email}`}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#999999",
                  }}
                >
                  {name}
                </a>
                <br />
                <span style={{ color: "#F06439" }}>{title}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{ maxWidth: "500px", border: "0", display: "block" }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td
                height="16"
                style={{ lineHeight: "1px", fontSize: "1px", padding: 0 }}
              >
                &nbsp;
              </td>
            </tr>
            {mobile && (
              <tr>
                <td
                  style={{
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#999999",
                  }}
                  valign="top"
                >
                  <strong>M:</strong>{" "}
                  <a
                    href={`tel:${formatMobileNumber(mobile).replace(
                      /\s/g,
                      ""
                    )}`}
                    style={{ color: "#999999" }}
                    ref={(element) => {
                      if (element)
                        element.style.setProperty(
                          "text-decoration",
                          "none",
                          "important"
                        )
                    }}
                  >
                    {formatMobileNumber(mobile)}
                  </a>
                  {!officePhone && discord ? <br /> : null}
                </td>
              </tr>
            )}
            {officePhone && (
              <tr>
                <td
                  style={{
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#999999",
                  }}
                  valign="top"
                >
                  <strong>T:</strong>{" "}
                  <a
                    href={`tel:${officePhone.replace(/\s/g, "")}`}
                    style={{ textDecoration: "none", color: "#999999" }}
                    ref={(element) => {
                      if (element)
                        element.style.setProperty(
                          "text-decoration",
                          "none",
                          "important"
                        )
                    }}
                  >
                    {officePhone}
                  </a>
                  <br />
                </td>
              </tr>
            )}
            {discord && (
              <tr>
                <td
                  style={{
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#999999",
                  }}
                  valign="top"
                >
                  <strong>Discord:</strong> {discord}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table
          style={{ maxWidth: "500px", border: "0", display: "block" }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td
                height="20"
                style={{ lineHeight: "1px", fontSize: "1px", padding: 0 }}
              >
                &nbsp;
              </td>
            </tr>
            <tr>
              <td valign="top" style={{ padding: 0 }}>
                <a href="https://www.fluidesign.co.uk" target="_blank">
                  <img
                    src="https://fluid-email.s3.eu-west-2.amazonaws.com/Fluid_Logo-retina-2.png"
                    width="1"
                    border="0"
                    style={{
                      border: 0,
                      height: "auto",
                      width: "100%",
                      maxWidth: "145px",
                    }}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{ maxWidth: "500px", border: "0" }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr>
              <td
                height="20"
                style={{ lineHeight: "1px", fontSize: "1px", padding: 0 }}
              >
                &nbsp;
              </td>
            </tr>
            <tr>
              <td valign="top">
                {mcvAward && (
                  <>
                    <td valign="top">
                      <img
                        src="https://fluid-email.s3.eu-west-2.amazonaws.com/MCV-Winner-Badge1.png"
                        width="64"
                        border="0"
                        style={{
                          border: 0,
                          marginTop: "4px",
                          height: "auto",
                          maxWidth: "64px",
                        }}
                      />
                    </td>
                    <td width="16">&nbsp;</td>
                  </>
                )}
                {agencyAward ? (
                  <>
                    <td valign="top">
                      <img
                        src="https://fluid-email.s3.eu-west-2.amazonaws.com/EUAgency-Winner-Badge-2.png"
                        width="112"
                        border="0"
                        style={{
                          border: 0,
                          marginTop: "4px",
                          height: "auto",
                          maxWidth: "112px",
                        }}
                      />
                    </td>
                  </>
                ) : null}
              </td>
              <td
                width="14"
                style={{ lineHeight: "1px", fontSize: "1px", padding: 0 }}
              >
                &nbsp;
              </td>
              <td valign="top">
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#999999",
                        }}
                        valign="top"
                      >
                        <a
                          href="https://www.google.co.uk/maps?f=q&source=s_q&hl=en&geocode&q=fluid+design+birmingham&aq=0&sll=34.112038,-118.590501&sspn=0.328026,0.727158&ie=UTF8&hq=fluid+design&hnear=Birmingham,+West+Midlands,+United+Kingdom&z=15"
                          style={{ color: "#999999" }}
                          ref={(element) => {
                            if (element)
                              element.style.setProperty(
                                "text-decoration",
                                "none",
                                "important"
                              )
                          }}
                          target="_blank"
                        >
                          Fluid Studios, 12 Tenby Street, <br /> Birmingham B1
                          3AJ
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height="4"
                        style={{
                          lineHeight: "1px",
                          fontSize: "1px",
                          padding: 0,
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={{ display: "flex" }} valign="top">
                        <a
                          href="https://www.linkedin.com/company/877553/"
                          target="_blank"
                        >
                          <img
                            src="https://fluid-email.s3.eu-west-2.amazonaws.com/icon-linkedin.png"
                            width="23"
                            border="0"
                            style={{
                              border: 0,
                              height: "auto",
                              width: "23px",
                              margin: "0 8px 0 0",
                            }}
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/fluidesign_uk/"
                          target="_blank"
                        >
                          <img
                            src="https://fluid-email.s3.eu-west-2.amazonaws.com/icon-instagram.png"
                            width="20"
                            border="0"
                            style={{
                              border: 0,
                              height: "auto",
                              width: "20px",
                              margin: "0 10px 0 0",
                            }}
                          />
                        </a>
                        <a
                          href="https://twitter.com/fluidesign_uk"
                          target="_blank"
                        >
                          <img
                            src="https://fluid-email.s3.eu-west-2.amazonaws.com/icon-twitter.png"
                            width="24"
                            border="0"
                            style={{
                              border: 0,
                              height: "auto",
                              width: "24px",
                            }}
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{ maxWidth: "500px", border: "0" }}
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr aria-colspan={2}>
              <td height="16" style={{ lineHeight: "1px", fontSize: "1px" }}>
                &nbsp;
              </td>
            </tr>
            <tr aria-colspan={2}>
              <td
                style={{
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontSize: "10px",
                  lineHeight: "13px",
                  color: "#999999",
                  width: "100%",
                }}
                valign="top"
              >
                All concepts/images included in this electronic mail remain ©
                copyright of {termsSwitch} T/A Fluid {year}.
                <br />
                <br />
                Privileged, confidential and/or copyright information may be
                contained In this email, and is only for the use of the intended
                addressee. To copy, forward, disclose or otherwise use it in any
                way if you are not The intended recipient or responsible for
                delivering to him/her is prohibited. If you receive this in
                error, please contact the sender and delete the material from
                any computer.
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </div>
  )
}
